import React, { Fragment, useState, useEffect } from 'react';
import { bool } from 'prop-types';
import { IconButton, Box } from '@chakra-ui/react';
import { MdMusicNote, MdMusicOff } from 'react-icons/md';

import isDevelopmentMode from '@invitato/helpers/dist/isDevelopmentMode';

import { DISABLED_MUSIC_IN_DEVELOPMENT } from '@/constants/feature-flags';
import { BG_NAVIGATION, BG_NAVIGATION_ICON_COLOR, DEFAULT_BUTTON_PROPS } from '@/constants/colors';
import SONG_CDN_URL from './assets/bg-sound.mp3';

function FloatingMusic({ shouldShowMusicIcon }) {
  const [play, setPlay] = useState(true);
  const [musicId, setMusicId] = useState('myAudio');

  /**
   * Side-effect to enable/disable music
   * @important Only disable music IF in development mode
   */
  useEffect(() => {
    if (DISABLED_MUSIC_IN_DEVELOPMENT && isDevelopmentMode()) {
      setMusicId('DISABLED_MUSIC');
    }
  }, []);

  /**
   * This function built-in with html5 function
   * doc: https://www.w3schools.com/tags/ref_av_dom.asp
   */
  const toggleMusic = () => {
    try {
      const myAudio = document.getElementById('myAudio');
      if (play) {
        myAudio.pause();
      } else {
        myAudio.play();
      }

      setPlay(!play);
    } catch {
      console.error('SOMETHING_WRONG_WHEN_PLAYING_MUSIC');
    }
  };

  return (
    <Fragment>
      <Box>
        <audio id={musicId} loop className="hide">
          <source src={musicId === 'DISABLED_MUSIC' ? '' : SONG_CDN_URL} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>

        {shouldShowMusicIcon && (
          <IconButton
            {...DEFAULT_BUTTON_PROPS}
            bgColor={BG_NAVIGATION}
            borderRadius="50%"
            position="fixed"
            zIndex="999"
            bottom="12px"
            left="48px"
            size="sm"
            boxShadow="md"
            onClick={toggleMusic}
            icon={
              play ? (
                <MdMusicNote color={BG_NAVIGATION_ICON_COLOR} />
              ) : (
                <MdMusicOff color={BG_NAVIGATION_ICON_COLOR} />
              )
            }
          />
        )}
      </Box>
    </Fragment>
  );
}

FloatingMusic.propTypes = {
  shouldShowMusicIcon: bool.isRequired,
};

export default React.memo(FloatingMusic);
